import { LightpointProperties } from "../components/GeoMapEnersis/GeoMap.types";
import { LightPoint } from "../models";

export namespace LightPointUtils {
  export function hasEqualLightpoints(lightPoints: LightPoint[], newLightPoints: LightPoint[]): boolean {
    if (newLightPoints.length > lightPoints.length) {
      return false;
    } else if (newLightPoints.length === 0) {
      return true;
    }
    for (const newLightPoint of newLightPoints) {
      for (const lightPoint of lightPoints) {
        if (isEqual(lightPoint, newLightPoint)) {
          break;
        }
        const isLastElement = lightPoint.number === lightPoints[lightPoints.length - 1].number;
        if (isLastElement) {
          return false;
        }
      }
    }
    return true;
  }

  export const isEqual = (l1: LightPoint, l2: LightPoint) => {
    if (l1.number === l2.number) {
      if (typeof l1.incident === typeof l2.incident) {
        if (l1.incident?.incidentStatus === l2.incident?.incidentStatus) {
          return true;
        }
      }
    }
    return false;
  };

  export const getLightPointAddress = (lightPoint: LightpointProperties): string => {
    const { zipCode, houseNumber: houseNo, street, city } = lightPoint;
    return [`${street}${houseNo ? " " + houseNo : ""}`, city, zipCode].filter((v) => !!v).join(", ");
  };

  export const getComplement = (l1: LightPoint[], l2: LightPoint[]): LightPoint[] => {
    return l1.filter((l) => !l2.some((l2) => isEqual(l, l2)));
  };
}
