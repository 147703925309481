import { ThemeProvider } from "@mui/material/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { AuthClientEvent, AuthClientTokens } from "@react-keycloak/core/lib";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import { AppContextProvider } from "./context";
import theme from "./theme";
import { StyledEngineProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./index.css";
import "./style/main.css";
import { authClient } from "./dataQuery";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { ErrorContextProvider } from "./context/ErrorContext/ErrorContext";
import { FullscreenSpinner } from "./parts";

const queryClient = new QueryClient();

const handleKeycloakEvent = (event: AuthClientEvent, error: unknown) => {
  //looks like a bug from library, no autorefresh, trigger refresh after token is expired.
  if (event === "onTokenExpired") {
    authClient.updateToken(50);
  }
  console.log("onKeycloakEvent", event, error);
};

const handleKeycloakTokens = (tokens: AuthClientTokens) => {
  console.log("onKeycloakTokens: ", tokens.token);
};

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={authClient}
    onEvent={handleKeycloakEvent}
    onTokens={handleKeycloakTokens}
    LoadingComponent={<FullscreenSpinner />}
    initOptions={{ enableLogging: true, onLoad: "login-required" }}
  >
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <BrowserRouter>
              <ErrorBoundary>
                <ErrorContextProvider>
                  <QueryClientProvider client={queryClient}>
                    <AppContextProvider>
                      <App />
                    </AppContextProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </QueryClientProvider>
                </ErrorContextProvider>
              </ErrorBoundary>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
