import React, {useEffect, useState} from 'react';
import ReleaseNotesData from '../../assets/ReleaseNotes.json';
import {ReleaseNote} from "../../models/ReleaseNote";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Link
} from "@mui/material";
import {Check, ExpandMore, NewReleases} from "@mui/icons-material";

export const ReleaseNotes = () => {
    const [releases, setReleases] = useState<ReleaseNote[]>([]);
    const [expandedPanel, setExpandedPanel] = useState(null);
    const [supportEmail, setSupportEmail] = useState('');

    useEffect(() => {
        setSupportEmail('support-strassenbeleuchtung@netze-bw.de');

        const sortedReleases = (ReleaseNotesData as ReleaseNote[]).sort((a, b) => (a.ordinal < b.ordinal ? 1 : -1));
        setReleases(sortedReleases);
    }, []);

    const handlePanelChange = (panelId) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? panelId : null);
    }

    return (
        <div className="container">
            <div className="page-header">
                <Typography variant="h1">
                    Release Notes
                </Typography>
            </div>
            <br />
            <div className="information" style={{ marginBottom: '20px' }}>
                <Typography variant="body1">
                    Nachfolgend finden Sie eine Übersicht über die Releases von DiCon und die jeweils realisierten Features.
                </Typography>
                <br />
                <Typography variant="body1">
                    Bei Fragen oder Problemen wenden Sie sich bitte an den{' '}
                    <Link href={`mailto:${supportEmail}`} color="secondary" rel="noopener noreferrer" underline='none'>
                        Support
                    </Link>
                </Typography>
            </div>
            {releases.map((rel) => (
                <Accordion key={rel.id} style={{ marginBottom: '15px' }} expanded={expandedPanel === rel.id} onChange={handlePanelChange(rel.id)}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <NewReleases style={{ marginRight: '8px' }} color={expandedPanel === rel.id ? 'secondary' : 'primary' }/>
                        <Typography style={{
                            paddingLeft: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            minWidth: '350px',
                            marginLeft: '10px'
                        }}><b>{rel.name}</b></Typography>
                        <Typography style={{
                            paddingLeft: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            minWidth: '350px',
                            marginLeft: '10px'
                        }}>{rel.date}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {rel.features && (
                            <>
                                <Typography variant="h2">Neue Funktionen für das Störungsmanagement</Typography>
                                <List>
                                    {rel.features.map((feature, index) => (
                                        <ListItem key={index} style={{ padding: '0' }}>
                                            <ListItemIcon><Check color="secondary"/></ListItemIcon>
                                            <ListItemText primary={feature} />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                        {rel.improvements && (
                            <>
                                <Typography variant="h2">Verbesserungen im Störungsmanagement</Typography>
                                <List>
                                    {rel.improvements.map((improvement, index) => (
                                        <ListItem key={index} style={{ padding: '0' }}>
                                            <ListItemIcon><Check color="secondary"/></ListItemIcon>
                                            <ListItemText primary={improvement} />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                        {rel.bugfixes && (
                            <>
                                <Typography variant="h2">Bugfixes im Störungsmanagement</Typography>
                                <List>
                                    {rel.bugfixes.map((bugfix, index) => (
                                        <ListItem key={index} style={{ padding: '0' }}>
                                            <ListItemIcon><Check color="secondary"/></ListItemIcon>
                                            <ListItemText primary={bugfix} />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                        {rel.detailsLink && (
                            <Typography variant="body1">
                                Detaillierte Informationen zum Release finden Sie{' '}
                                <Link href={rel.detailsLink} target="_blank">
                                    hier
                                </Link>
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};
